<template>
  <div class="dropdown-component" ref="dropdownComponent" tabindex="2">
    <div
      class="dropdown-component-outside"
      @click="open"
      :class="{ 'up-side-down': dropdownUpsideDown && opened, 'dropdown-list-opened': opened }">
      <p class="dropdown-component-outside-text" v-if="multiSelect ? getMultiSelectNames() : value">
        {{ errorMessage ? placeHolder + ' - ' + errorMessage : placeHolder }}
      </p>
      <p
        class="dropdown-component-outside-title"
        :class="{ 'normal-color': multiSelect ? getMultiSelectNames() : value }">
        {{
          multiSelect
            ? getMultiSelectNames()
              ? getMultiSelectNames()
              : errorMessage
              ? placeHolder + ' - ' + errorMessage
              : placeHolder
            : value && displaySelected()
            ? displaySelected()
            : errorMessage
            ? placeHolder + ' - ' + errorMessage
            : placeHolder
        }}
      </p>
      <div class="dropdown-arrow-box"></div>
    </div>
    <div
      class="dropdown-component-opened"
      v-if="opened"
      v-click-outside="close"
      ref="dropdownComponentList"
      :class="{ 'up-side-down': dropdownUpsideDown }">
      <div class="search-box" v-if="searchBox">
        <InputComponent ref="inputComponent" v-model="searchValue" placeHolder="Search" />
      </div>
      <ul>
        <li v-if="!multiSelect && !revertOption">
          <input type="radio" :value="null" id="null_option" v-model="value" />
          <label for="null_option"></label>
        </li>
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          :class="{
            highlighted: focusedItemIndex === index,
          }">
          <div class="checkbox" v-if="multiSelect">
            <input type="checkbox" :value="option" :id="valueFunction(option) + '_' + index" v-model="value" />
            <label
              :for="valueFunction(option) + '_' + index"
              :title="displayFunction(option)"
              class="body-text radio-label"
              >{{ displayFunction(option) }}</label
            >
          </div>
          <template v-else>
            <input type="radio" :value="option" :id="valueFunction(option) + '_' + index" v-model="value" />
            <label :for="valueFunction(option) + '_' + index" :title="displayFunction(option)">{{
              displayFunction(option)
            }}</label>
          </template>
        </li>
        <li v-if="filteredOptions.length == 0">
          <label>No results found</label>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { withDefaults, onMounted, computed, ref, nextTick, defineEmits, defineProps, onBeforeUnmount } from 'vue';
import InputComponent from '../input-component/InputComponent.vue';

type Option =
  | {
      name: string;
      value: string;
    }
  | unknown;

const emit = defineEmits(['update:modelValue']);

const props = withDefaults(
  defineProps<{
    placeHolder?: string;
    errorMessage?: string;
    modelValue: unknown;
    options: Option[] | null;
    displayFunction?: (option: Option) => string;
    valueFunction?: (option: Option) => string;
    multiSelect?: boolean;
    searchBox?: boolean;
    revertOption?: boolean;
  }>(),
  {
    displayFunction: (option: Option) => {
      return (
        (
          option as {
            name: string;
          }
        ).name ?? '--'
      );
    },
    valueFunction: (option: Option) => {
      return (
        (
          option as {
            value: string;
          }
        ).value ?? '--'
      );
    },
    placeHolder: 'Please select',
    multiSelect: false,
    searchBox: false,
    revertOption: false,
  }
);

const opened = ref(false);
const dropdownUpsideDown = ref(false);
const searchValue = ref('');
const inputComponent = ref<typeof InputComponent | null>();
const dropdownComponent = ref<HTMLElement | null>();
const dropdownComponentList = ref<HTMLElement | null>();

const value = computed({
  get(): unknown | null {
    if (!props.options) {
      return null;
    }
    if (props.multiSelect) {
      return props.modelValue ?? [];
    }
    for (let i = 0; i < props.options.length; i++) {
      if (props.valueFunction(props.options[i]) === props.modelValue) {
        return props.options[i];
      }
    }

    return null;
  },
  set(val: unknown | null) {
    if (val) {
      if (props.multiSelect) {
        emit('update:modelValue', val);
      } else {
        emit('update:modelValue', props.valueFunction(val));
        close();
      }
    } else if (!props.revertOption && !props.multiSelect && val === null) {
      emit('update:modelValue', val);
      close();
    }
  },
});

const filteredOptions = computed(() => {
  if (!props.options) {
    return [];
  }
  if (searchValue.value) {
    return props.options.filter((option) => {
      return props.displayFunction(option).toLowerCase().includes(searchValue.value.toLowerCase());
    });
  }
  return props.options;
});

onMounted(() => {
  checkLength();
  window.addEventListener('resize', calculateDropdownPosition);

  window.addEventListener('scroll', handleScroll, true);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', calculateDropdownPosition);

  window.removeEventListener('scroll', handleScroll, true);
});

function handleScroll(e: Event) {
  const scrolledElement = e.target as HTMLElement;
  if (scrolledElement.contains(dropdownComponent.value as Node)) {
    close();
  }
}

function checkLength() {
  if (props.options && props.options.length == 1) {
    value.value = props.options[0];
  }
}

const typed = ref('');
const timeoutTyped = ref(0);
const focusedItemIndex = ref(-1);

function handleKeyDown(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    close();
  } else if (e.key === 'ArrowDown') {
    e.preventDefault();

    if (focusedItemIndex.value + 1 >= filteredOptions.value.length) {
      return;
    }
    focusedItemIndex.value++;

    const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
    focusedItem?.scrollIntoView({ block: 'nearest' });
  } else if (e.key === 'ArrowUp') {
    e.preventDefault();

    if (focusedItemIndex.value === -1) {
      focusedItemIndex.value = filteredOptions.value.length - 1;
    } else if (focusedItemIndex.value > 0) {
      focusedItemIndex.value--;
    }

    const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
    focusedItem?.scrollIntoView({ block: 'nearest' });
  } else if (e.key === 'Enter') {
    if (focusedItemIndex.value >= 0) {
      if (
        props.multiSelect &&
        filteredOptions.value[focusedItemIndex.value] &&
        (value.value as unknown[]).includes(filteredOptions.value[focusedItemIndex.value])
      ) {
        value.value = (value.value as unknown[]).filter(
          (item: Option) => item !== filteredOptions.value[focusedItemIndex.value]
        );
      } else if (props.multiSelect && filteredOptions.value[focusedItemIndex.value]) {
        value.value = [...(value.value as unknown[]), filteredOptions.value[focusedItemIndex.value]];
      } else if (filteredOptions.value[focusedItemIndex.value]) {
        value.value = filteredOptions.value[focusedItemIndex.value];
        close();
      }
    }
  } else if (
    ((e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 65 && e.keyCode <= 90) ||
      (e.keyCode >= 96 && e.keyCode <= 105)) &&
    !inputComponent.value?.isInFocus()
  ) {
    typed.value += e.key;
    const filteredOptions = props.options?.filter((option) => {
      return props.displayFunction(option).toLowerCase().startsWith(typed.value.toLowerCase());
    });
    if (timeoutTyped.value) {
      clearTimeout(timeoutTyped.value);
    }

    timeoutTyped.value = window.setTimeout(() => {
      typed.value = '';
    }, 1000);
    if (filteredOptions && filteredOptions?.length > 0) {
      focusedItemIndex.value = props.options?.indexOf(filteredOptions[0]) ?? -1;
      const focusedItem = dropdownComponentList.value?.querySelectorAll('li')[focusedItemIndex.value];
      focusedItem?.scrollIntoView({ block: 'nearest' });
    } else {
      focusedItemIndex.value = -1;
    }
  }
}

function displaySelected() {
  if (value.value) {
    return props.displayFunction(value.value);
  }
  return props.placeHolder;
}

function open() {
  opened.value = true;

  calculateDropdownPosition();
  nextTick(() => {
    dropdownComponent.value?.focus();
    dropdownComponent.value?.addEventListener('keydown', handleKeyDown);
    if (props.searchBox) {
      inputComponent.value?.focusInput();
    }
  });
}
function close() {
  opened.value = false;
  dropdownUpsideDown.value = false;
  searchValue.value = '';
  focusedItemIndex.value = -1;
  dropdownComponent.value?.removeEventListener('keydown', handleKeyDown);
}

function calculateDropdownPosition() {
  nextTick(() => {
    const dropdownComponentListPosition = dropdownComponentList.value?.getBoundingClientRect();
    const dropdownPosition = dropdownComponent.value?.getBoundingClientRect();
    const MIN_HEIGHT = 100;
    const MIN_GAP = 50;

    if (!dropdownComponentListPosition || !dropdownPosition) {
      return;
    }

    let parentElement = dropdownComponentList.value?.parentElement;
    let height = 0;
    while (parentElement) {
      if (parentElement === document.body) {
        height = window.innerHeight;
        break;
      }
      if (parentElement.scrollHeight > parentElement.clientHeight) {
        height = parentElement.getBoundingClientRect().bottom;
        break;
      }

      parentElement = parentElement.parentElement;
    }

    const spaceToBottom = height - dropdownPosition.bottom;

    const spaceRequired = dropdownComponentListPosition.height;
    let top = dropdownPosition.bottom + 'px';
    const left = dropdownPosition.left + 'px';
    let bottom = 'auto';
    let maxHeight = Math.max(spaceToBottom, MIN_HEIGHT) - MIN_GAP;

    if (Math.min(spaceRequired, 250) <= spaceToBottom) {
      dropdownUpsideDown.value = false;
    } else {
      dropdownUpsideDown.value = true;
      top = 'auto';
      const spaceToBottom = window.innerHeight - dropdownPosition.bottom;
      bottom = spaceToBottom + dropdownPosition.height + 'px';
      maxHeight = Math.max(dropdownPosition.top, MIN_HEIGHT) - MIN_GAP;
    }

    dropdownComponentList.value?.setAttribute(
      'style',
      `top: ${top};
       left: ${left};
       bottom: ${bottom};
       width: ${dropdownPosition.width}px;
       max-height: ${maxHeight}px;
      `
    );
  });
}

function getMultiSelectNames() {
  const selectedValue = value.value as unknown[];
  if (props.multiSelect && selectedValue && selectedValue?.length > 0) {
    let names = '';
    for (let i = 0; i < selectedValue?.length; i++) {
      if (!props.options) return null;
      for (let j = 0; j < props.options.length; j++) {
        if (props.options[j] === selectedValue[i]) {
          names += props.displayFunction(props.options[j]) + ', ';
        }
      }
      names = names.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    names = names.slice(0, -2);
    if (selectedValue?.length > 3) {
      names = selectedValue?.length + ' ' + props.placeHolder + 's' + ' selected ';
    }
    return names;
  }
  return null;
}
</script>

<style lang="scss">
.dropdown-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;

  &:focus {
    outline: none;
  }

  --error-color: #ff6565;
  --primary-color: #1a2c51;
  --secondary-color: #8d96a8;
  --hover-color: #223a6b;
  --black-color: #000;
  --white-color: #fff;

  * {
    box-sizing: border-box;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    left: -99999px;
  }

  &.is-invalid {
    .dropdown-component-outside {
      border: 1px solid var(--error-color);
      .dropdown-arrow-box {
        border-left: 1px solid var(--error-color);
      }
      p {
        color: var(--error-color);
      }
    }
    .dropdown-component-opened {
      border: 1px solid var(--error-color);
      border-top: none;
      &.up-side-down {
        border: 1px solid var(--error-color);
        border-bottom: none;
      }
    }
  }

  .dropdown-component-outside {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    background: var(--white-color);
    border: 1px solid var(--secondary-color);
    border-radius: 8px;

    &.dropdown-list-opened {
      border-radius: 8px 8px 0 0;
      .dropdown-arrow-box {
        &::before {
          margin-top: 5px;
          transform: rotate(135deg);
        }
      }
      &.up-side-down {
        border-radius: 0 0 8px 8px;
        .dropdown-component-outside-text {
          top: auto;
          bottom: -10px;
        }
      }
    }

    .dropdown-component-outside-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: var(--secondary-color);
      width: calc(100% - 40px);
      padding: 0 10px;
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.normal-color {
        color: var(--black-color);
      }
    }
    .dropdown-arrow-box {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 10px;
      border-left: 1px solid var(--secondary-color);
      height: 100%;
      box-sizing: border-box;
      width: 40px;
      &::before {
        content: '';
        display: inline-block;
        width: 11.5px;
        height: 11.5px;
        margin-top: -5px;
        border-bottom: 2px solid var(--primary-color);
        border-left: 2px solid var(--primary-color);
        transform: rotate(-45deg);
      }
    }
    .dropdown-component-outside-text {
      height: auto;
      position: absolute;
      top: -10px;
      left: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color: var(--black-color);
      padding: 5px;
      z-index: 5;
      margin: 0;
      background-color: var(--white-color);
    }
    &:hover {
      cursor: pointer;
      border: 1px solid var(--primary-color);
      .dropdown-arrow-box {
        border-left: 1px solid var(--primary-color);
      }
    }
  }
  .dropdown-component-opened {
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    border-radius: 0 0 8px 8px;
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    border: 1px solid var(--secondary-color);
    border-top: none;
    z-index: 6;
    padding: 0;

    &.up-side-down {
      top: auto;
      bottom: 40px;
      flex-direction: column-reverse;
      border-radius: 8px 8px 0 0;
      border-top: 1px solid var(--secondary-color);
      border-bottom: none;
      .search-box {
        border-bottom: none;
        border-top: 1px solid var(--secondary-color);
      }
    }

    input[type='checkbox'],
    input[type='radio'] {
      position: absolute;
      opacity: 0;
      left: -99999px;
    }
    .search-box {
      padding: 10px;
      border-bottom: 1px solid var(--secondary-color);
    }
    ul {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      overflow-y: auto;
      padding: 0 10px;
      width: 100%;

      li {
        min-height: 40px;
        height: 40px;
        border-bottom: 1px solid var(--secondary-color);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        &.highlighted {
          background-color: #f5f5f5;
        }

        .checkbox {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;

          input[type='checkbox'] + label::before {
            content: '';
            display: inline-block;
            width: 17px;
            height: 17px;
            background: url('./checkboxIcons.svg') 0 0;
            background-repeat: no-repeat;
            margin-right: 6px;
            margin-bottom: -3px;
            flex-shrink: 0;
          }

          input[type='checkbox']:checked + label::before {
            background: url('./checkboxIcons.svg') 0 -19px;
            background-repeat: no-repeat;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        label {
          display: inline-block;
          font-weight: 400;
          font-size: 15px;
          line-height: 22px;
          color: var(--black-color);
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 10px 0;
          height: 100%;
          &:hover {
            cursor: pointer;
            color: var(--hover-color);
          }
        }
      }
    }
  }
}
</style>
