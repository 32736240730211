import { defineStore } from "pinia";
import { User, Resource, Client } from "@/types";
import axios from "axios";

type generalStore = {
  users: User[];
  resources: Resource[];
  clients: Client[];
  grants: string[];
  loading: boolean;
};

export const generalStore = defineStore("generalStore", {
  state: (): generalStore => {
    return {
      users: [],
      resources: [],
      clients: [],
      grants: [],
      loading: false,
    };
  },
  getters: {},
  actions: {
    getUsers() {
      return axios.get("/api/users").then((response) => {
        this.users = response.data;
      });
    },
    getResources() {
      return axios.get("/api/resources").then((response) => {
        this.resources = response.data;
      });
    },
    getClients() {
      return axios.get("/api/clients").then((response) => {
        this.clients = response.data;
      });
    },

    getGrants() {
      return axios.get("/api/grants").then((response) => {
        this.grants = response.data;
      });
    },

    resourcesDropdown() {
      return this.resources.map((resource) => {
        return {
          name: resource.id,
          value: resource.id,
        };
      });
    },

    clientsDropdown() {
      return this.clients.map((client) => {
        return {
          name: client.id,
          value: client.id,
        };
      });
    },

    usersDropdown() {
      return this.users.map((user) => {
        return {
          name: user.email,
          value: user.id,
        };
      });
    },

    formatComas(value: string) {
      return value.split(",") || [];
    },

    getData() {
      return Promise.all([this.getResources(), this.getClients(), this.getUsers(), this.getGrants()]);
    },
  },
});
