import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import Directives from "./directives";
import { createPinia } from "pinia";
import Format from "./mixins/Format";
import { useToaster } from "vue-toaster-component";

import InputComponent from "./components/input-component/InputComponent.vue";
import DropdownComponent from "./components/dropdown-component/DropdownComponent.vue";

const app = createApp(App);
const pinia = createPinia();

declare module "vue" {
  interface ComponentCustomProperties {
    $format: Format;
  }
}

const toaster = useToaster();
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    toaster.addToast({
      title: "Error",
      message: error.response.data.message,
      type: "error",
    });
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

app.config.globalProperties.$format = new Format();
Directives(app);
app.use(router);
app.use(pinia);
app.component("InputComponent", InputComponent);
app.component("DropdownComponent", DropdownComponent);

app.mount("#app");
