<template>
  <div class="navigation-component">
    <router-link :to="{ name: 'UsersView' }" class="navigation-title">Authentication dashboard</router-link>
    <div class="navigation-links">
      <router-link
        class="navigation-item list-text-bold"
        :to="{ name: 'UsersView' }"
        :class="{
          active: includesName($route.name, 'User'),
        }"
        >Users</router-link
      >
      <router-link
        class="navigation-item list-text-bold"
        :to="{ name: 'ResourcesView' }"
        :class="{
          active: includesName($route.name, 'Resource'),
        }"
        >Resources</router-link
      >

      <router-link
        class="navigation-item list-text-bold"
        :to="{ name: 'ClientsView' }"
        :class="{
          active: includesName($route.name, 'Clients'),
        }"
        >Clients</router-link
      >
      <router-link
        class="navigation-item list-text-bold"
        :to="{ name: 'SystemsView' }"
        :class="{
          active: includesName($route.name, 'Systems'),
        }"
        >Systems</router-link
      >
    </div>
    <div class="navigation-end">
      <a href="/logout" class="navigation-item list-text-bold"> Logout </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RouteRecordName } from "vue-router";
function includesName(outter: RouteRecordName | null | undefined, inner: string) {
  if (!outter) return false;
  const outterString = outter.toString();
  return outterString.includes(inner);
}
</script>
