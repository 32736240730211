<template>
  <NavigationComponent />
  <router-view />
  <div class="loader-container" v-if="store.loading">
    <div class="loader"></div>
  </div>
  <div class="not-supported">
    <p class="heading-2-bold">Quadratura auth dashboard</p>
    <div class="not-supported-box">
      <img src="@/assets/icons/notSupported.svg" />
      <p class="heading-2-bold">The application is optimised for screen width 950 px or more.</p>
      <p class="heading-2-bold">Please resize your browser window or rotate the tablet.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import NavigationComponent from "@/components/NavigationComponent.vue";
import { generalStore } from "@/store/generalStore";

const store = generalStore();
</script>

<style lang="scss">
@import "@/scss/main.scss";
#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}
</style>
